import React from "react";
import ImageTransform from "../ggfx-client/module/components/image-transform";
import { AltSiteName } from "./utils";
const GetGGFXImage =(props)=> {
    return (
        <ImageTransform
                    imagesources={props.imagesources && props.imagesources.url}
                    renderer={props.renderer != "undefined" ? props.renderer : "SrcSet"}
                    imagename={props.imagename}
                    loadLazy={props.loadLazy }
                    attr={{
                      alt: props.imagesources&&props.imagesources.alternativeText
                        ? props.imagesources.alternativeText+AltSiteName
                        : props.fallbackalt+AltSiteName
                    }}
                    imagetransformresult={props.imagetransformresult}
                    id={props.id}
                  />
    )   
}
export default GetGGFXImage;
